@font-face {
  font-family: "Graphik Web";
  src: url("./fonts/Graphik-Black-Web.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family: "Graphik Web";
  src: url("./fonts/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Graphik Web";
  src: url("./fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Graphik Web";
  src: url("./fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Graphik Web";
  src: url("./fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
}

:root {
  --gray: #868e96;
  --green: #1affac;
  --purple: #6524ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #000;
  font-family: "Graphik Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
}

.wrapper {
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1280px;
}

.arrow {
  margin-left: 1rem;
}

/* ====================================== */
/* *** HEADER *************************** */
/* ====================================== */

header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 3rem 0 6rem 0;
}

/* logo */
h1 {
  align-items: center;
  background: #000;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 1.75rem;
  height: 2.75rem;
  justify-content: center;
  padding-bottom: 2px;
  width: 2.75rem;
}

h1:hover {
  background: var(--gray);
  color: #000;
}

/* navigation */
.nav-link {
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 3rem;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--gray);
}

/* ====================================== */
/* *** INTRODUCTION ********************* */
/* ====================================== */

.intro {
  margin-bottom: 6rem;
  max-width: 60%;
}

.intro-headline {
  font-size: 4.75rem;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.intro-description {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
}

/* ====================================== */
/* *** PROJECTS GRID ******************** */
/* ====================================== */

.projects {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.project {
  align-items: center;
  background: #dee2e6;
  display: flex;
  height: 550px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: transform 0.4s ease-out;
}

.project-info {
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 3rem;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-out;
  width: 100%;
}

.project-info-visible {
  opacity: 1;
}

.project-preview {
  bottom: 3rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  left: 3rem;
  line-height: 1.3;
  margin-bottom: 1rem;
  position: absolute;
  right: 3rem;
  top: 3rem;
}

.explore {
  background: none;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
}

/* ====================================== */
/* *** FOOTER *************************** */
/* ====================================== */

/* contact box */
.contact {
  align-items: flex-end;
  background-color: #000;
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr auto;
  margin-top: 12rem;
  padding: 4rem;
}

.contact-headline {
  color: #fff;
  font-size: 2.25rem;
  margin-bottom: 0.75rem;
}

.contact-description {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 0;
}

.contact-button {
  background: #fff;
  border-radius: 5px;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
}

.contact-button:hover {
  background: var(--gray);
}

/* footer */
footer {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0 3rem 0;
}

.footer-copyright {
  color: #868e96;
  font-size: 1.25rem;
}

.footer-link {
  color: #868e96;
  font-size: 1.25rem;
  margin-left: 2rem;
  text-decoration: none;
}

.footer-link:hover {
  color: #000;
}

/* ====================================== */
/* *** PROJECT DETAILS ****************** */
/* ====================================== */

.details-title {
  font-size: 4rem;
  line-height: 1;
  margin: 0 auto 1rem auto;
  max-width: 680px;
}

.details-description {
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0 auto 1.25rem auto;
  max-width: 680px;
}

/* image rotation */
.details-image-container {
  align-items: center;
  background: #dee2e6;
  display: flex;
  height: 550px;
  justify-content: center;
  margin: 3rem auto;
  max-width: 1040px;
  overflow: hidden;
  position: relative;
}

.details-image {
  display: none;
}

.visible {
  display: block;
}

.dots {
  position: absolute;
  right: 1.625rem;
  top: 2rem;
}

.dot-container {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  padding: 0.375rem;
}

.dot {
  background: #818283;
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
}

.dot-active {
  background: #000;
}

@media (max-width: 1024px) {
  .intro {
    max-width: 75%;
  }

  .project {
    height: 450px;
  }

  .project-info {
    padding: 1.5rem;
  }

  .project-preview {
    font-size: 1.25rem;
  }

  .project-preview {
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    top: 1.5rem;
  }

  .explore {
    font-size: 1rem;
    padding: 0.75rem 0.5rem;
  }

  .arrow {
    margin-left: 0.5rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }

  .intro {
    margin-bottom: 6rem;
    max-width: 90%;
  }

  .projects {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .project {
    grid-column: auto / span 2 !important;
    width: 100% !important;
  }

  .project-preview {
    bottom: 2.5rem;
    font-size: 1.5rem;
    left: 2.5rem;
    right: 2.5rem;
    top: 2.5rem;
  }

  .explore {
    font-size: 1.25rem;
    padding: 1rem 1.5rem;
  }

  .contact {
    align-items: flex-end;
    background-color: #000;
    display: grid;
    gap: 4rem;
    grid-template-columns: 1fr;
    margin-top: 12rem;
    padding: 4rem;
  }

  .contact-button {
    display: inline;
  }

  .tablet {
    height: 350px;
  }

  .phone {
    height: 350px;
  }

  .arrow {
    margin-left: 1rem;
  }
}
